import { CLOUDINARY_URL } from 'config'

export const getServicesBrokers = () => [
  {
    image: {
      alt: 'Fotos de 3 imóveis lado a lado',
      src: `${CLOUDINARY_URL}/homepage/encontramos-seu-imovel`,
    },
    title: 'Encontramos seu imóvel em qualquer lugar',
    description:
      'Caso seu imóvel não esteja na EmCasa, nós encontramos ele em outros portais, ou com outros parceiros.',
  },
  {
    image: {
      alt: 'Corretores da EmCasa em treinamento',
      src: `${CLOUDINARY_URL}/homepage/corretores-treinados`,
    },
    title: 'Corretores treinados',
    description:
      'Ferramentas, treinamentos e incentivos, tudo para o seu processo de compra e venda ser excelente.',
  },
  {
    image: {
      alt: 'Assessoria para financiar seu imóvel',
      src: `${CLOUDINARY_URL}/homepage/assessoria-para-financiamentos`,
    },
    title: 'Assessoria para financiar seu imóvel',
    description:
      'Te ajudamos a encontrar a melhor taxa e a ter o seu financiamento no menor prazo possível',
  },
  {
    image: {
      alt: 'Compre e venda sem surpresa com nosso apoio jurídico',
      src: `${CLOUDINARY_URL}/homepage/apoio-juridico`,
    },
    title: 'Compre e venda sem surpresa com nosso apoio jurídico',
    description:
      'Nossos corretores e advogados, te direcionam durante todo o processo para ter uma transação mais segura',
  },
]
