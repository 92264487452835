import Typography from 'components/Typography'
import List from 'components/List'
import styles from './FAQ.module.scss'

export const tabsItems = [
  { label: 'Compradores', value: 'buyers' },
  { label: 'Vendedores', value: 'sellers' },
  { label: 'Financiamento', value: 'mortgage' },
]

export const accordionBuyers = [
  {
    size: 'small',
    title: 'O serviço da EmCasa tem custo para o comprador?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          <strong>Nenhum.</strong> Para quem compra, os nossos serviços são
          totalmente gratuitos desde o começo até o final de todo o processo.
          Nada sai do seu bolso, nem mesmo o custo da comissão dos nossos
          corretores, pois ele é pago pelo proprietário do imóvel.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Como funcionam as etapas de compra?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Depois de encontrar o imóvel ideal, nossos corretores te apoiam na
          negociação da proposta, assinatura do contrato preliminar de compra e
          venda (<abbr>PCV</abbr>), na assessoria jurídica e na assinatura final
          da escritura ou no contrato de financiamento. Nesse último caso, a
          etapa de vistoria também é necessária.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Além do valor do imóvel, o que mais tenho que pagar?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Apenas despesas habituais comuns em uma transação imobiliária, como
          impostos e taxas cartoriais. Dentre elas:
        </Typography>
        <List
          size="small"
          list={[
            <>
              <abbr>
                <strong>ITBI</strong>
              </abbr>{' '}
              (Imposto de Transmissão de Bens Imóveis): imposto municipal que
              varia de acordo com a cidade, podendo chegar até 3% do valor
              avaliado pela Prefeitura ou do valor da negociação — o que for
              maior.
            </>,
            <>
              <strong>Escritura:</strong> documento que formaliza a
              transferência do imóvel do vendedor para o comprador. Pode ser
              pública ou particular (contrato de financiamento). O seu custo
              varia de estado para estado e de acordo com o valor do imóvel.
            </>,
            <>
              <strong>Registro:</strong> Após o pagamento do ITBI e assinatura
              da escritura, o imóvel precisa ser registrado no cartório. O custo
              também varia de estado para estado e de acordo com o valor do
              imóvel.
            </>,
          ]}
        />
      </div>
    ),
  },
  {
    size: 'small',
    title: 'A EmCasa também aluga imóveis?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Não trabalhamos com locação, apenas com compra e venda de imóveis.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Posso utilizar meu FGTS?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          <strong>Sim.</strong> É necessário apenas cumprir os pré-requisitos
          estabelecidos pelo governo.
        </Typography>
      </div>
    ),
  },
]

export const accordionSellers = [
  {
    size: 'small',
    title: 'Tem custo para anunciar na EmCasa?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Não existe nenhum custo para anunciar seu imóvel conosco. Caso o seu
          imóvel seja vendido pela EmCasa, apenas será cobrada uma taxa de
          comissão, conforme estabelecido pelo <abbr>CRECI</abbr> (Conselho
          Regional de Fiscalização do profissional Corretor de Imóveis) de cada
          estado.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Qual tipo de imóvel posso anunciar na EmCasa?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Trabalhamos apenas com imóveis residenciais (casas e apartamentos).
          Imóveis comerciais ou terrenos, por exemplo, não são anunciados em
          nosso site.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Meu imóvel atualmente está alugado. Posso anunciar mesmo assim?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Sim. Mas é importante lembrar que os locatários possuem preferência de
          compra. Então, antes de anunciar, não esqueça de consultar seu
          locatário e verificar se o mesmo tem interesse em realizar a compra.
          Caso não haja interesse, você pode anunciar conosco.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Posso anunciar um imóvel para alugar?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Não. A EmCasa não trabalha com locação, apenas com a compra e venda de
          imóveis residenciais.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Se o imóvel for vendido, quanto preciso pagar de comissão?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          O valor da comissão pode variar de acordo com cada estado (a partir de
          5%) e conforme o tipo de imóvel que está sendo negociado.
        </Typography>
      </div>
    ),
  },
]

export const accordionMortgage = [
  {
    size: 'small',
    title: 'Como funciona a assessoria de financiamento da EmCasa?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          Nossos corretores negociam as melhores taxas com os principais bancos
          do mercado, pesquisando e apresentando diversas opções até você
          encontrar a que mais faz sentido. Além disso, também agilizamos a
          aprovação do crédito, para que você pegue as chaves do seu imóvel
          ainda mais rápido.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Como funciona o financiamento imobiliário?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          O financiamento imobiliário é dividido em 3 etapas:
        </Typography>
        <List
          orderedList
          size="small"
          list={[
            <>
              <strong>Aprovação do crédito:</strong> O banco analisa a
              capacidade de pagamento do comprador.
            </>,
            <>
              <strong>Vistoria do imóvel:</strong> Um especialista indicado pelo
              banco verifica se o valor negociado corresponde ao valor real de
              mercado.
            </>,
            <>
              <strong>Análise jurídica:</strong> Toda a documentação do
              comprador e vendedor são analisadas pelo banco, para que seja
              feito o contrato de financiamento.
            </>,
          ]}
        />
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Qual é o valor mínimo para entrada do imóvel?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          O valor de entrada deve ser de, no mínimo,{' '}
          <strong>20% do valor total do imóvel.</strong> Em nosso site, cada
          anúncio de imóvel possui um simulador de financiamento, onde você pode
          ter uma prévia de valores de entrada, preço do imóvel, tempo e
          parcelas.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title: 'Qual é o máximo de parcelas que posso realizar o financiamento?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          O prazo máximo para quitar a sua dívida pode variar dependendo da
          instituição escolhida. Os bancos costumam oferecer um prazo máximo de
          420 meses (35 anos), porém, você pode escolher um prazo que forneça
          uma mensalidade que mais se adeque ao seu orçamento.
        </Typography>
      </div>
    ),
  },
  {
    size: 'small',
    title:
      'Quanto tempo leva para análise de crédito para financiamento junto ao banco?',
    content: (
      <div className={styles.ecHomeFAQAccordionItemContent}>
        <Typography variant="ParagraphSmall">
          O prazo em média é de 2 a 3 dias úteis para resposta, mas o tempo de
          retorno da aprovação de crédito pode variar de acordo com a
          instituição financeira escolhida.
        </Typography>
      </div>
    ),
  },
]
